import styled from "styled-components"
import { flexCenter, frameMedium, heading } from "../mixins"
import { Link } from "gatsby"

export const Card = styled(Link)`
  ${({ theme }) => `
     ${flexCenter()}
     ${frameMedium({ theme })}
     h2 {
        ${heading({ theme }, theme["font-size"])}
     }
     justify-content: left;
     flex-wrap: nowrap;
     height: auto;
     transition: ${theme["transition-time"]} background-color;
     &:hover {
       background-color: ${theme["color-transparent-darker"]};
     }
     @media ${theme["mobile"]} {
       margin: ${theme["margin-zero"]};
     }   
       `};
`

export const Cover = styled.div`
  ${({ theme }) => `
    margin: ${theme["margin-zero"]} ${theme["margin-small"]};
    width: 10%;
    min-width: 10%;
    margin-right: ${theme["margin-medium"]} * 2;
    @media ${theme["mobile"]} {
        min-width: 25%;
    }
    @media ${theme["tablet"]} {
        min-width: 17%;
    }
       `};
`

export const Content = styled.div`
  ${({ theme }) => `
    h2 {
        font-family: 'CustomBoldFont', sans-serif;
    }
    h2,
    p {
        margin-top: ${theme["margin-zero"]};
        margin-bottom: ${theme["margin-small"]};
        text-align: left;
    }
       `};
`
